
.selected-warehouse-name {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
}

.dashboard {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
}

.status {
    font-size: 18px;
    text-align: center;
    margin: 24px;
    color: green;
}

.status.connected {
    color: green;
}

.status.connecting {
    color: #804200;
}
