.logs {
    padding: 24px;
}

.log-container {
    margin: 12px 0;
    height: 120px;
}

.timestamp {
    color: #c1c1c1;
    font-size: 12px;
}

.log-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.log-level {
    font-weight: bold;
}

.log-level-debug {
    color: grey;
}

.log-level-info {
    color: green;
}

.log-level-warn {
    color: yellow;
}

.log-level-error {
    color: red;
}

@keyframes fadein {
    0% {
        opacity: 0;
        padding: 0;
        margin: 0;
        height: 0;
    }

    50% {
        opacity: 0;
        padding: 24px;
        margin: 12px;
        height: 120px;
    }

    100% {
        opacity: 1;
        height: 120px;
    }
}

.log-received {
    animation: 0.2s ease-in-out fadein;
}
