.selectable-warehouse {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    cursor: pointer;
    color: inherit;
    margin: 18px 0;
    font-size: 16px;
}

.selectable-warehouse:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.warehouse-list {
    padding: 24px;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
}

.warehouse-list h1 {
    color: #1c4736;
    margin-bottom: 32px;
}
